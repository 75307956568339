@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
  margin: 0;
  font-family: "Rubik", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Rubik", sans-serif;

}

.checkjoinChannelBTN {
  font-size: 10px;
  color: #000000;
  background-color: #ffe007;
  border: none;
  border-radius: 15px;
  width: 47px;

  font-family: 'Rubik';
  margin-right: 10px;
  text-align: center;
  box-shadow: rgb(241 205 29 / 50%) 0px 4px 10px;
  padding-bottom: 5px;
}

.checkjoinChannelBTNLoading {
  font-size: 10px;
  color: #000000;
  background-color: #e7e7e6;
  border: none;
  border-radius: 15px;
  width: auto;

  font-family: 'Rubik';
  margin-right: 10px;
  text-align: center;
  box-shadow: rgba(232, 230, 230, 0.5) 0px 4px 10px;
  padding-bottom: 5px;
}

.checkjoinChannelBTNSuccess {
  font-size: 10px;
  color: #f0f0f0;
  background-color: #219000;
  border: none;
  border-radius: 15px;
  width: auto;

  font-family: 'Rubik';
  margin-right: 10px;
  text-align: center;
  box-shadow: rgba(3, 165, 33, 0.5) 0px 4px 10px;
  padding-bottom: 5px;
}

.joinChannelBTN {
  color: #fff;
  background-color: #ff4c07;
  border: none;
  border-radius: 20px;
  width: 81px;
  height: 27px;
  font-family: 'Rubik';
  margin-bottom: 5px;
  text-align: center;
  padding-bottom: 5px;
  box-shadow: rgb(185 42 19 / 90%) 0px 4px 10px
}

.fontRubik {
  font-family: "Rubik", sans-serif;
}

.bookMotion {
  width: 38%;
  margin-bottom: 5px;
}

.gemMotion {
  width: 10%;
  margin-right: 15px;
  margin-left: 15px;
}

#root {
  background-color: #272727;
}

.nextBtn {
  background-color: #272727;
  border-radius: 20px;
  color: #fff8e8;
  font-family: "Rubik";
  padding: 10px 30px;
  text-transform: none;
  font-size: 18px;
  width: 230px;
  border: 0.2px #303030 solid;
  margin-bottom: 10px;
  width: 100%;
  text-shadow: #fff8e8 1px 3px 20px;
}

.nextBtn:disabled {
  background-color: #272727;
  border-radius: 20px;
  color: #616161;
  font-family: "Rubik";
  padding: 10px 30px;
  text-transform: none;
  font-size: 18px;
  width: 230px;
  border: 0.2px #303030 solid;
  margin-bottom: 10px;
  width: 100%;
  text-shadow: #2b2b2b 1px 3px 20px;
}

.answerBTN {
  background-color: #fff8e8;
  border-radius: 20px;
  color: #3a3a3a;
  font-family: "Rubik";
  padding: 10px 30px;
  text-transform: "none";
  font-size: 18px;
  font-weight: bold;
  width: 230px;
  border: "0.2px #303030 solid";
  margin-bottom: 10px;
  width: 100%
}

.correct {
  background-color: #d2e900;
  color: rgb(0, 84, 0)
}

.incorrect {
  background-color: #bf440c;
  color: rgb(84, 24, 0)
}

.boxWave {
  background: hsl(0, 0%, 100%);
  padding: 16px 24px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .01);

  &::after {
    position: absolute;
    content: "";
    top: 15px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(0.9) translateZ(0);
    filter: blur(15px);
    background: linear-gradient(to left,
        #ff5770,
        #e4428d,
        #c42da8,
        #9e16c3,
        #6501de,
        #9e16c3,
        #c42da8,
        #e4428d,
        #ff5770);
    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
  }
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 200% 50%;
  }
}




@keyframes flipOut {
  0% {
    transform: rotateY(0);
    opacity: 1;
  }

  100% {
    transform: rotateY(90deg);
    opacity: 0;
  }
}

@keyframes flipIn {
  0% {
    transform: rotateY(-90deg);
    opacity: 0;
  }

  100% {
    transform: rotateY(0);
    opacity: 1;
  }
}

.flashcard {

  display: inline-block;
  transition: transform 0.6s ease, opacity 0.6s ease;
}

.flashcard.flipping-out {
  animation: flipOut 0.6s forwards;
}

.flashcard.flipping-in {
  animation: flipIn 0.6s forwards;
}